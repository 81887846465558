import { template as template_9ce5bba14a2b44bd948bff826e1afd64 } from "@ember/template-compiler";
const FKLabel = template_9ce5bba14a2b44bd948bff826e1afd64(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
