import { template as template_1e06a5667e6c4df0a46e1e2e81bfd24d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_1e06a5667e6c4df0a46e1e2e81bfd24d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
