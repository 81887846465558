import { template as template_0619f5d3fc834b3ea0960d97b5f5e5f9 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_0619f5d3fc834b3ea0960d97b5f5e5f9(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
